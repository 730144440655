import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { MainSection } from "../components/main-section"
import { ThreeCards } from "../components/three-card"
import { ProcessBanner } from "../components/process-banner"
import { HomeBody } from "../components/home-body"
import { PriceCalcBanner } from "../components/price-calc-banner"
import { ClientReviews } from "../components/client-reviews"
import { WhyUs } from "../components/why-us"
import GalleryComponent from "../components/image-gallery"

const IndexPage = (props) => {
  const reviewData = props.data.allContentfulReviews.edges[0].node
  const projectsData = props.data.allContentfulProjects.edges
  return (
    <Layout>
      <Seo
        title="Sell My House In Sacramento, CA - Trusted Sacramento Home Buyer"
        description="We are a local Sacramento house buying company, and we can make you a fast & fair offer if you are 
        trying to sell your Sacramento House. If you want to sell your house fast in Sacramento, let us give you a 
        FREE no-obligation offer so that you can sell your Sacramento House today!"
        keywords={[
          "sell my house in sacramento",
          "sell my house fast in sacramento",
          "sell my sacramento house",
          "sacramento house buyer",
          "sell my house for cash in sacramento",
          "cash house buyer in sacramento"
        ]}
        location={props.location.pathname}
      />
      <div>
        <MainSection city="Sacramento" />

        <WhyUs />

        <ClientReviews reviewData={reviewData} />

        <ProcessBanner />

        <ThreeCards />

        <PriceCalcBanner />

        <HomeBody city="Sacramento" />

        <p className="text-center text-2xl lg:text-3xl font-bold pt-10">Homes We Have Worked With</p>
        <div className="container mx-auto grid grid-cols-2 md:grid-cols-3 px-5 gap-x-5 xl:grid-cols-5 pb-10">
          {projectsData.slice(0, 15).map((project, index) => (
            <GalleryComponent key={index} project={project.node} />
          ))}
        </div>
        <Link to='/projects'>
          <button className="m-5 relative h-10 py-2 px-5 mx-auto transition-colors rounded-lg focus:shadow-outline bg-gradient-to-r from-dhredfrom to-dhredto text-white hover:shadow-lg hover:shadow-dhred font-semibold flex">
            <span>See More Projects</span>
          </button>
        </Link>


      </div>
    </Layout>
  )
}

export default IndexPage
export const pageQuery = graphql`
query {
    site {
        siteMetadata {
            title
        }
    }
    allContentfulReviews {
        edges {
          node {
            reviews {
                id
                author
                date
                rating
                review
                avatar
              }
          }
        }
      }
      allContentfulProjects {
        edges {
          node {
            title {
                title
            }
            images {
              title
              description
              gatsbyImageData
              url
            }
          }
        }
      }
}
`